import React from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite"

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 40,
    marginLeft: 0,
    marginRight: 40,
    marginBottom: 40,
  },
}))

const SortButton = ({ startSort, sortClicked }) => {
  const classes = useStyles()

  const sort = () => {
    startSort()
  }

  return (
    <div>
      <Button
        disabled={sortClicked}
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={sort}
        startIcon={<PlayCircleFilledWhiteIcon />}
      >
        Sort
      </Button>
    </div>
  )
}

export default SortButton
