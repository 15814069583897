import React, { useState, useRef } from "react"
import Box from "@material-ui/core/Box"
import TicBox from "./TicBox"
import TicDialog from "./TicDialog"
import { makeStyles } from "@material-ui/core/styles"
import RestartButton from "./RestartButton"

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    backgroundColor: "white",
    marginTop: 30,
    margin: "auto",
  },
  text: {
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
  },
})

const TicTacToe = () => {
  const classes = useStyles()

  let reloadButton = null
  let terminated = useRef(false)
  let mark = useRef("")
  let count = useRef(0)
  let [player, setPlayer] = useState("")
  let [AI, setAI] = useState("")
  let [board, setBoard] = useState([
    ["", "", ""],
    ["", "", ""],
    ["", "", ""],
  ])

  let minimax_score = {
    X: AI === "X" ? 1 : -1,
    O: AI === "O" ? 1 : -1,
    tie: 0,
  }

  const setMark = (player, AI) => {
    setPlayer(player)
    setAI(AI)
  }

  const minimax = (isMaximizing) => {
    // Check if we have a winner yet
    checkWin()
    if (mark.current !== null) {
      return minimax_score[mark.current]
    }

    if (isMaximizing) {
      let bestScore = -Infinity
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
          // AI's turn
          if (board[i][j] === "") {
            board[i][j] = AI
            let score = minimax(false)
            board[i][j] = ""
            bestScore = Math.max(score, bestScore)
          }
        }
      }
      return bestScore
    } else {
      let bestScore = Infinity
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
          // Human's turn
          if (board[i][j] === "") {
            board[i][j] = player
            let score = minimax(true)
            board[i][j] = ""
            bestScore = Math.min(score, bestScore)
          }
        }
      }
      return bestScore
    }
  }

  const AImove = () => {
    let bestScore = -Infinity
    let bestMove
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        if (board[i][j] === "") {
          board[i][j] = AI
          let score = minimax(false)
          board[i][j] = ""

          if (score > bestScore) {
            bestScore = score
            bestMove = { i, j }
          }
        }
      }
    }
    terminated.current = false
    board[bestMove.i][bestMove.j] = AI
  }

  const equal = (a, b, c) => {
    return a === b && b === c && c !== ""
  }

  const checkWin = () => {
    mark.current = null

    // Check Vertical
    for (let i = 0; i < 3; i++) {
      if (equal(board[0][i], board[1][i], board[2][i])) {
        mark.current = board[0][i]
      }
    }

    // Check Horizontal
    for (let i = 0; i < 3; i++) {
      if (equal(board[i][0], board[i][1], board[i][2])) {
        mark.current = board[i][0]
      }
    }

    // Check Diagonal
    if (equal(board[0][0], board[1][1], board[2][2])) {
      mark.current = board[1][1]
    }
    if (equal(board[0][2], board[1][1], board[2][0])) {
      mark.current = board[1][1]
    }

    // Check Tie
    count.current = 0
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        if (board[i][j] !== "") {
          count.current++
        }
      }
    }

    if (mark.current !== null) {
      terminated.current = true
    } else if (count.current === 9) {
      mark.current = "tie"
      terminated.current = true
    }
  }

  const changeMark = (row, col) => {
    // const row = Math.floor(index / 3)
    // const col = index % 3
    if (board[row][col] === "") {
      board[row][col] = player
      checkWin()

      // Use minimax AI to predict moves
      if (!terminated.current) {
        AImove()
        checkWin()
      }
      setBoard([...board])
    }
  }

  const renderBoard = () => {
    let id = 0
    let tiles = []
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        tiles.push(
          <TicBox
            key={id}
            disabled={terminated.current}
            player={board[i][j]}
            changeMark={changeMark}
            index={id}
          />
        )
        id++
      }
    }

    return tiles
  }

  // Checking for winners first before checking for tie.
  const renderMessage = () => {
    if (mark.current === AI) {
      return <Box className={classes.text}>AI Win!</Box>
    } else if (mark.current === player) {
      return <Box className={classes.text}>You Win!</Box>
    } else if (mark.current === "tie") {
      return <Box className={classes.text}>Tie!</Box>
    } else {
      return <Box className={classes.text}>Hello!</Box>
    }
  }

  const relaod = () => {
    window.location.reload(false)
  }

  // Show the reload-page button if the game is finished
  if (terminated.current) {
    reloadButton = <RestartButton reload={relaod} />
  }

  if (player === "") {
    return <TicDialog setMark={setMark} />
  } else {
    return (
      <>
        <div>{renderMessage()}</div>
        <Box className={classes.container} width={350} height={350}>
          {/* {renderBoard()} */}
          {board.map((row, i) =>
            row.map((col, j) => (
              <TicBox
                key={`${i}${j}`}
                disabled={terminated.current}
                player={board[i][j]}
                changeMark={changeMark}
                row={i}
                col={j}
              />
            ))
          )}
        </Box>
        <div>{reloadButton}</div>
      </>
    )
  }
}

export default TicTacToe
