import React from "react";
import ProjectCard from "./ProjectCard";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const Projects = ({ cards }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="center">
        {cards.map((card, index) => {
          return (
            <ProjectCard
              key={index}
              name={card.name}
              description={card.description}
              image={card.image}
              link={card.link}
            />
          );
        })}
      </Grid>
    </div>
  );
};

export default Projects;
