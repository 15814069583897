import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles({
  canvas: {
    backgroundColor: "#222629",
    height: "100%",
  },
  name: {
    fontSize: 40,
    textAlign: "center",
    color: "#ffffff",
    paddingTop: 50,
  },
  line: {
    backgroundColor: "#ffffff",
    width: "10%",
  },
  welcome: {
    margin: "auto",
    fontSize: 18,
    color: "#6B6E70",
    textAlign: "center",
  },
  content: {
    margin: "auto",
    fontSize: 18,
    color: "#6B6E70",
    textAlign: "center",
  },
  contact: {
    backgroundColor: "#86C232",
    position: "fixed",
    bottom: 16,
    right: 16,
  },
})

const Home = () => {
  const classes = useStyles()

  const openLinkedIn = () => {
    window.open("https://www.linkedin.com/in/colt-liu")
  }

  return (
    <>
      <div className={classes.canvas}>
        <div className={classes.name}>
          Colt Liu
          <hr className={classes.line} />
        </div>

        <p className={classes.welcome}>Welcome to my playground.</p>
        <p className={classes.content}>
          I'm an undergraduate student at Brigham Young University studying
          computer science.
        </p>
        <p className={classes.content}>
          I love software engineering and meeting new people.
        </p>

        <Button
          className={classes.contact}
          onClick={openLinkedIn}
          variant="contained"
          color="secondary"
        >
          Contact
        </Button>
      </div>
    </>
  )
}

// Passing the history object as a prop by wrapping NavBar with withRouter()
export default Home
