import React from "react"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import blue from "@material-ui/core/colors/blue"
import grey from "@material-ui/core/colors/grey"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  container: {
    width: 200,
    height: 120,
    borderWidth: 1,
    marginTop: 50,
    borderRadius: 10,
    borderColor: blue[300],
    margin: "auto",
    backgroundColor: "white",
    borderStyle: "solid",
  },
  sign: {
    marginTop: 20,
    fontSize: 20,
    fontWeight: "300",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: blue[300],
    borderRadius: 10,
    "&:hover": {
      backgroundColor: grey[400],
      color: "#FFF",
    },
  },
  options: {
    display: "flex",
    justifyContent: "space-around",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
    fontSize: 45,
    fontWeight: "100",
  },
  message: {
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    fontWeight: "300",
  },
})

const TicDialog = ({ setMark }) => {
  const classes = useStyles()

  const CircleClose = () => {
    setMark("O", "X")
  }

  const CrossClose = () => {
    setMark("X", "O")
  }

  return (
    <div>
      <Box className={classes.header}>Welcome to TicTacToe AI</Box>
      <Box className={classes.container}>
        <Box className={classes.message}>Pick your mark!</Box>
        <Box className={classes.options}>
          <Box>
            <Button className={classes.sign} onClick={CircleClose}>
              O
            </Button>
          </Box>
          <Box>
            <Button className={classes.sign} onClick={CrossClose}>
              X
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default TicDialog
