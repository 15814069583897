import React from "react"
import Box from "@material-ui/core/Box"
import grey from "@material-ui/core/colors/grey"
import green from "@material-ui/core/colors/green"
import red from "@material-ui/core/colors/red"
import purple from "@material-ui/core/colors/purple"
import blue from "@material-ui/core/colors/blue"

const ValBar = ({ barHeight, arraySize, status }) => {
  let barColor
  if (status === 0) {
    barColor = grey[900]
  } else if (status === 1) {
    barColor = green[500]
  } else if (status === 2) {
    barColor = red[900]
  } else if (status === 3) {
    barColor = purple[400]
  } else if (status === 4) {
    barColor = blue[500]
  }

  let barWidth
  if (arraySize <= 10) {
    barWidth = 35
  } else if (arraySize > 10 && arraySize <= 20) {
    barWidth = 25
  } else if (arraySize > 20 && arraySize <= 45) {
    barWidth = 15
  } else if (arraySize > 45 && arraySize <= 70) {
    barWidth = 9
  } else {
    barWidth = 7
  }

  return (
    <Box
      marginTop={5}
      height={barHeight / 4}
      bgcolor={barColor}
      mx={0.1}
      width={barWidth}
      display="inline-block"
    ></Box>
  )
}

export default ValBar
