import React from "react"
import Button from "@material-ui/core/Button"
import blue from "@material-ui/core/colors/blue"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  mark: {
    width: 105,
    height: 105,
    marginTop: 5,
    fontSize: 30,
    borderRadius: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: blue[300],
    backgroundColor: "white",
  },
})

const TicBox = ({ disabled, player, changeMark, row, col }) => {
  const classes = useStyles()

  const change = () => {
    changeMark(row, col)
  }

  return (
    <Button
      disabled={disabled}
      className={classes.mark}
      variant="contained"
      onClick={change}
    >
      {player}
    </Button>
  )
}

export default TicBox
