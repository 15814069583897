import React from "react"
import Button from "@material-ui/core/Button"
import EqualizerIcon from "@material-ui/icons/Equalizer"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 40,
    marginBottom: 40,
    marginLeft: 40,
    marginRight: 40,
  },
}))

const RandButton = ({ startRand, sortClicked }) => {
  const classes = useStyles()

  const rand = () => {
    startRand()
  }

  return (
    <div>
      <Button
        disabled={sortClicked}
        onClick={rand}
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={<EqualizerIcon />}
      >
        Randomize
      </Button>
    </div>
  )
}

export default RandButton
