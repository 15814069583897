import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import NativeSelect from "@material-ui/core/NativeSelect"

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 30,
    minWidth: 120,
    marginLeft: 15,
    marginRight: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const AlgoSelecter = ({
  returnSelectedAlgo,
  returnSelectedSpeed,
  sortClicked,
  setAlgoSpeed,
}) => {
  const classes = useStyles()
  const [selectedAlgo, setAlgo] = useState("bubble")
  const [selectedSpeed, setSpeed] = useState("fast")

  useEffect(() => {
    setAlgoSpeed(selectedAlgo, selectedSpeed)
  }, [])

  const changeAlgo = (event) => {
    setAlgo(event.target.value)
    returnSelectedAlgo(event.target.value)
  }

  const changeSpeed = (event) => {
    setSpeed(event.target.value)
    returnSelectedSpeed(event.target.value)
  }

  // Use disabled prop to disable the form
  return (
    <div>
      <FormControl disabled={sortClicked} className={classes.formControl}>
        <InputLabel shrink htmlFor="age-native-label-placeholder">
          Algorithm
        </InputLabel>
        <NativeSelect value={selectedAlgo} onChange={changeAlgo}>
          <option value={"bubble"}>Bubble Sort</option>
          <option value={"merge"}>Merge Sort</option>
          <option value={"quick"}>Quick Sort</option>
          <option value={"radix"}>Radix Sort</option>
        </NativeSelect>
      </FormControl>
      <FormControl disabled={sortClicked} className={classes.formControl}>
        <InputLabel shrink htmlFor="age-native-label-placeholder">
          Sort Speed
        </InputLabel>
        <NativeSelect value={selectedSpeed} onChange={changeSpeed}>
          <option value={"slow"}>Slow</option>
          <option value={"medium"}>Medium</option>
          <option value={"fast"}>Fast</option>
          <option value={"lightning"}>Lightning</option>
        </NativeSelect>
      </FormControl>
    </div>
  )
}

export default AlgoSelecter
