import React from "react"
import Slider from "@material-ui/core/Slider"
import { withStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
    padding: 5,
    marginTop: 40,
    marginRight: 40,
  },
}))

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider)

const ArraySlider = ({ returnVal, sortClicked }) => {
  const classes = useStyles()

  const getValue = (_event, val) => {
    returnVal(val)
  }

  return (
    <div className={classes.root}>
      <div className={classes.margin} />
      <PrettoSlider
        disabled={sortClicked}
        onChange={getValue}
        valueLabelDisplay="auto"
        defaultValue={5}
        min={5}
        max={100}
      />
      <div className={classes.margin} />
    </div>
  )
}

export default ArraySlider
