import React from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import ReplayIcon from "@material-ui/icons/Replay"
import Box from "@material-ui/core/Box"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
  },
}))

const SortButton = ({ reload }) => {
  const classes = useStyles()

  const refreshPage = () => {
    reload()
  }

  return (
    <Box className={classes.container}>
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={refreshPage}
        startIcon={<ReplayIcon />}
      >
        Play Again
      </Button>
    </Box>
  )
}

export default SortButton
