import React from "react"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 20,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1.5,
  },
  media: {
    height: 140,
  },
})

const ProjectCard = ({ name, description, image, link }) => {
  const classes = useStyles()
  const startAppInNewTab = () => {
    window.open(link)
  }

  return (
    <Card
      className={classes.root}
      // on-Click is expecting a function.
      // Passing the defined function as a pointer/reference to on-Click event.
      onClick={startAppInNewTab}

      // You can also do it this way, Anonymous function style
      // onClick={(event, link) => {
      //     window.open(link)
      // }}
    >
      <CardActionArea>
        <CardMedia className={classes.media} component="img" image={image} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default ProjectCard
