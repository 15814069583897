import React, { useRef, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  canvas: {
    width: "100%",
    height: "100%",
    backgroundColor: "black",
  },
})

const Canvas = (props) => {
  const classes = useStyles()
  const canvasRef = useRef(null)
  const swarm = 150
  const bugSize = 1
  const bugColor = "#c4c4c4"
  const flashColor = "#fff3a1"

  let mouseX = null
  let mouseY = null
  let context = null
  let canvas = null
  let canvasWidth = null
  let canvasHeight = null
  let dots = []
  let directionClockMax = 100
  let normalSpeed = 0.01
  let crazySpeed = 0.1
  let webEvent = true

  const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const randomDirection = () => {
    if (randomIntFromInterval(1, 4) % 2 === 0) {
      return "+"
    } else {
      return "-"
    }
  }

  const generateBugs = () => {
    for (let i = 0; i < swarm; i++) {
      dots.push({
        x: randomIntFromInterval(1, 100),
        y: randomIntFromInterval(1, 100),
        centerX: randomIntFromInterval(1, 300),
        centerY: randomIntFromInterval(1, 150),
        bugSize: bugSize,
        radians: randomIntFromInterval(0, 10),
        velocity: normalSpeed,
        crazy: false,
        directionClock: randomIntFromInterval(1, directionClockMax),
        flashClock: randomIntFromInterval(1, 10000),
        radius: randomIntFromInterval(1, 3),
        xMove: randomDirection(),
        yMove: randomDirection(),
      })
    }
  }

  const movementTuner = (min, max, base) => {
    return (
      randomIntFromInterval(min, max) / base +
      randomIntFromInterval(min, max) / base
    )
  }

  const isWithinRange = (dot) => {
    if (!mouseX || !mouseY) {
      return 0
    }
    if (webEvent) {
      const target = 100
      return (
        Math.abs(dot.x * (1440 / 300) - mouseX) < target &&
        Math.abs(dot.y * (800 / 150) - mouseY) < target
      )
    } else {
      console.log("here")
      const target = 10
      return (
        Math.abs(dot.x - mouseX) < target && Math.abs(dot.y - mouseY) < target
      )
    }
  }

  useEffect(() => {
    canvas = canvasRef.current
    context = canvas.getContext("2d")
    canvasWidth = context.canvas.width
    canvasHeight = context.canvas.height

    canvas.addEventListener("mousemove", (e) => {
      webEvent = true
      mouseX = e.offsetX
      mouseY = e.offsetY
    })

    canvas.addEventListener("touchmove", (e) => {
      // console.log(e.offsetX + " " + e.offsetY)

      console.log(e.touches[0].clientX + " " + e.touches[0].clientY)
      webEvent = false
      console.log(webEvent)
      mouseX = e.touches[0].clientX
      mouseY = e.touches[0].clientY
    })

    // Generating a swarm of fireflies
    generateBugs()

    setTimeout(function () {
      window.requestAnimationFrame(moveDot)
    }, 1000)

    const moveDot = () => {
      context.clearRect(0, 0, canvasWidth, canvasHeight)

      // Iterate over all the dots.
      for (let i = 0; i < dots.length; i++) {
        // The math that makes the bug go in circular motion
        if (dots[i].crazy) {
          dots[i].x =
            dots[i].centerX + dots[i].radius * Math.cos(dots[i].radians) * 2
          dots[i].y =
            dots[i].centerY + dots[i].radius * Math.sin(dots[i].radians) * 2
          dots[i].flashClock = randomIntFromInterval(1, 100)
        } else {
          dots[i].x =
            dots[i].centerX + dots[i].radius * Math.cos(dots[i].radians)
          dots[i].y =
            dots[i].centerY + dots[i].radius * Math.sin(dots[i].radians)
          dots[i].flashClock = randomIntFromInterval(1, 1000000)
        }

        dots[i].flashClock = randomIntFromInterval(1, 1000000)

        // detecting if a bug should go crazy
        if (isWithinRange(dots[i])) {
          dots[i].crazy = true
        } else {
          dots[i].crazy = false
        }

        // making the bugs go crazy
        if (dots[i].crazy) {
          dots[i].velocity = crazySpeed
        } else {
          dots[i].velocity = normalSpeed
        }

        if (dots[i].crazy) {
          dots[i].velocity = crazySpeed
          if (dots[i].directionClock === directionClockMax / 25) {
            // Randomizing the direciton of the bug
            dots[i].xMove = randomDirection()
            dots[i].yMove = randomDirection()
          }
        } else {
          dots[i].velocity = normalSpeed
          if (dots[i].directionClock === directionClockMax / 2) {
            // Randomizing the direciton of the bug
            dots[i].xMove = randomDirection()
            dots[i].yMove = randomDirection()
          }
        }

        // Update the timer clock
        if (dots[i].directionClock == directionClockMax) {
          dots[i].directionClock = 0
        } else {
          dots[i].directionClock += 1
        }

        // controls the direction and the speed of the bug
        if (dots[i].radians % 3 === 0) {
          dots[i].radians += dots[i].velocity + movementTuner(1, 10, 10000)
        } else {
          dots[i].radians -= dots[i].velocity + movementTuner(1, 10, 10000)
        }

        // Moving the X center so th bug can roam around the canvas
        if (dots[i].xMove === "-") {
          dots[i].centerX -= movementTuner(1, 10, 100)
        } else {
          dots[i].centerX += movementTuner(1, 10, 100)
        }

        // Moving the Y center so th bug can roam around the canvas
        if (dots[i].yMove === "-") {
          dots[i].centerY -= movementTuner(1, 10, 100)
        } else {
          dots[i].centerY += movementTuner(1, 10, 100)
        }

        if (dots[i].crazy) {
          // FLASH CRAZY
          if (dots[i].flashClock % 3 === 0) {
            flashDot(dots[i], flashColor, 3.5)
          }
        } else if (
          dots[i].flashClock % 17 === 0 &&
          dots[i].flashClock % 23 === 0
        ) {
          // FLASH NORMALLY
          flashDot(dots[i], flashColor, 3.5)
        } else {
          drawDot(dots[i], bugColor, 1)
        }

        // wall detection
        if (dots[i].x + dots[i].bugSize >= canvasWidth) {
          dots[i].xMove = "-"
        } else if (dots[i].x - dots[i].bugSize <= 0) {
          dots[i].xMove = "+"
        }

        if (dots[i].y + dots[i].bugSize >= canvasHeight) {
          dots[i].yMove = "-"
        } else if (dots[i].y - dots[i].bugSize <= 0) {
          dots[i].yMove = "+"
        }
      }

      // Render it again
      window.requestAnimationFrame(moveDot)
    }

    const drawDot = (dot, color, dotRadius) => {
      context.beginPath()
      context.arc(dot.x, dot.y, dotRadius, 0, 2 * Math.PI, false)
      context.fillStyle = color
      context.fill()
      context.closePath()
    }

    const flashDot = (dot, color, dotRadius) => {
      context.beginPath()
      context.arc(dot.x, dot.y, dotRadius, 0, 2 * Math.PI, false)

      let gradient = context.createLinearGradient(0, 0, 3, 0)
      gradient.addColorStop("0.8", "yellow")

      context.fillStyle = gradient
      context.fill()
      context.closePath()
    }
  })

  return (
    <>
      <canvas className={classes.canvas} ref={canvasRef} {...props} />
    </>
  )
}

export default Canvas
