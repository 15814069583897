import React from "react"
import Paper from "@material-ui/core/Paper"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import HomeIcon from "@material-ui/icons/Home"
import AppsIcon from "@material-ui/icons/Apps"
// import BlogIcon from "@material-ui/icons/InsertComment"
import grey from "@material-ui/core/colors/grey"
import { makeStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"

const useStyles = makeStyles({
  nav: {
    backgroundColor: "#263238",
  },
})

const NavBar = ({ history }) => {
  const classes = useStyles()
  const hideNav = ["/apps/sort", "/apps/tictactoe", "/apps/firefly"]

  // Setting the url in the browser, Depends on the value of the tab
  const handleCallToRouter = (_event, tabValue) => {
    history.push(tabValue)
  }

  // Hiding the nav bar when user opens an App in the new tab
  if (hideNav.includes(history.location.pathname)) {
    return null
  } else {
    return (
      <div>
        <Paper square className={classes.nav}>
          <Tabs
            value={history.location.pathname}
            onChange={handleCallToRouter}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            // aria-label="icon label tabs example"
          >
            <Tab
              style={{ color: grey[50] }}
              icon={<HomeIcon style={{ color: grey[50] }} />}
              label="HOME"
              value="/"
            />
            <Tab
              style={{ color: grey[50] }}
              icon={<AppsIcon style={{ color: grey[50] }} />}
              label="APPS"
              value="/apps"
            />
            {/* <Tab
              style={{ color: grey[50] }}
              icon={<BlogIcon style={{ color: grey[50] }} />}
              label="BLOGS"
              value="/blog"
            /> */}
          </Tabs>
        </Paper>
      </div>
    )
  }
}

// Passing the history object as a prop by wrapping NavBar with withRouter()
export default withRouter(NavBar)
