import React, { useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import ArraySlider from "./ArraySlider"
import SortButton from "./SortButton"
import RandButton from "./RandButton"
import AlgoSpeedPicker from "./AlgoSpeedPicker"
import Box from "@material-ui/core/Box"
import ValBar from "./ValBar"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  text: {
    marginTop: 40,
    marginRight: 5,
    marginBottom: 20,
    marginLeft: 0,
    padding: 10,
  },
  speedText: {
    marginTop: 0,
  },
  bars: {
    display: "flex",
    justifyContent: "center",
  },
})

const SortVisualizer = () => {
  // 0 = black, 1 = green, 2 = red, 3 = purple, 4 = blue
  // To update an array, you need to directly modify the old one and copy it to the Setter
  let [array, updateArray] = useState([])
  let [selectedAlgo, updateSelectedAlgo] = useState("")
  let [selectedSpeed, updateSelectedSpeed] = useState("")
  let [sortStarted, setSortStarted] = useState(false)

  // Initializing the array on-Mount to give user a random array to work with
  useEffect(() => {
    updateArray(generateArray(5, []))
  }, [])

  const setPicker = (algo, speed) => {
    updateSelectedAlgo(algo)
    updateSelectedSpeed(speed)
  }

  // Generating an array that we are going to sort with
  const generateArray = (size, newArray) => {
    for (let i = 0; i < size; i++) {
      let num = getRandomIntFromRange(5, 2000)
      newArray.push([num, 0])
    }
    return newArray
  }

  const getRandomIntFromRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  // Getting the value from the Slider component
  const getVal = (newVal) => {
    //Creating a new set of array/numbers when user drags the slider
    if (array.length !== newVal) {
      updateArray(generateArray(newVal, []))
    }
  }

  //Randomnize the array with the button using the previous array size
  const random = () => {
    let preSize = array.length
    updateArray(generateArray(preSize, []))
  }

  async function sort() {
    setSortStarted(true)
    let speed
    if (selectedSpeed === "slow") {
      speed = 500
    } else if (selectedSpeed === "medium") {
      speed = 200
    } else if (selectedSpeed === "fast") {
      speed = 20
    } else {
      speed = 0
    }

    if (selectedAlgo === "bubble") {
      await bubbleSort(speed)
      // await testAlgo()
      setSortStarted(false)
    } else if (selectedAlgo === "merge") {
      await mergeSort(0, array.length - 1, speed)
      // await testAlgo()
      setSortStarted(false)
    } else if (selectedAlgo === "quick") {
      await quickSort(0, array.length - 1, speed)
      // await testAlgo()
      setSortStarted(false)
    } else if (selectedAlgo === "radix") {
      await radixSort(speed)
      // await testAlgo()
      setSortStarted(false)
    }
  }

  async function doubleColoring(first, second, delay, color) {
    await new Promise((resolve) =>
      setTimeout(() => {
        array[first][1] = color
        array[second][1] = color
        updateArray([...array])
        resolve()
      }, delay)
    )
  }

  const swap = (l, r) => {
    let temp = array[r][0]
    array[r][0] = array[l][0]
    array[l][0] = temp
  }

  async function bubbleSort(delay) {
    let isSorted = false
    let range = array.length - 1
    // If we ever swap elements, we need to while-loop again and check everything again
    while (!isSorted) {
      isSorted = true
      // Every loop we make sure at least one element is in place
      for (let i = 0; i < range; i++) {
        // Setting the bar that we are visiting to green
        if (delay !== 0) {
          await doubleColoring(i, i + 1, delay, 1)
        }

        if (array[i][0] > array[i + 1][0]) {
          // Swapping the values
          swap(i, i + 1)

          // Setting the bar that we are swapping to red
          if (delay !== 0) {
            await doubleColoring(i, i + 1, delay, 2)
          }
          //We swapped something, it is possible that the array is not in order now.
          isSorted = false
        }

        // Setting the bar back to black
        if (delay !== 0) {
          await doubleColoring(i, i + 1, delay, 0)
        }
      }
      range--
    }
  }

  async function singleColoring(index, delay, color) {
    await new Promise((resolve) =>
      setTimeout(() => {
        array[index][1] = color
        updateArray([...array])
        resolve()
      }, delay)
    )
  }

  async function mergeSort(l, r, delay) {
    let mid = Math.floor((l + r) / 2)

    if (l < r) {
      await mergeSort(l, mid, delay)
      await mergeSort(mid + 1, r, delay)

      let i = l
      let j = mid + 1
      let l_max = mid
      let r_max = r
      let temp = []
      while (i <= l_max && j <= r_max) {
        if (array[i][0] < array[j][0]) {
          if (delay !== 0) {
            await singleColoring(i, delay, 1)
          }
          temp.push(array[i][0])
          i += 1
        } else {
          if (delay !== 0) {
            await singleColoring(j, delay, 1)
          }
          temp.push(array[j][0])
          j += 1
        }
      }
      while (i <= l_max) {
        if (delay !== 0) {
          await singleColoring(i, delay, 1)
        }
        temp.push(array[i][0])
        i += 1
      }
      while (j <= r_max) {
        if (delay !== 0) {
          await singleColoring(j, delay, 1)
        }
        temp.push(array[j][0])
        j += 1
      }

      let k = 0
      for (let i = l; i < r_max + 1; i++) {
        array[i][0] = temp[k]
        if (delay !== 0) {
          await singleColoring(i, delay, 0)
        }
        k += 1
      }
    }
  }

  async function partition(l, r, delay) {
    let pivotVal = array[r][0]

    // Color the pivot value
    if (delay !== 0) {
      await singleColoring(r, delay, 3)
    }

    let partitionIndex = l
    for (let i = l; i < r; i++) {
      if (delay !== 0) {
        await doubleColoring(i, partitionIndex, delay, 1)
      }
      if (array[i][0] <= pivotVal) {
        swap(i, partitionIndex)
        if (delay !== 0) {
          await doubleColoring(i, partitionIndex, delay, 2)
          await doubleColoring(i, partitionIndex, delay, 0)
        }
        partitionIndex++
      }
      if (delay !== 0) {
        await doubleColoring(i, partitionIndex, delay, 0)
      }
    }
    swap(partitionIndex, r)
    if (delay !== 0) {
      await doubleColoring(partitionIndex, r, delay, 4)
      await doubleColoring(partitionIndex, r, delay, 0)
    }
    return partitionIndex
  }

  async function quickSort(l, r, delay) {
    if (l < r) {
      // Partitioning an array to left sub-array and right sub-array
      let splitIndex = await partition(l, r, delay)

      // Sort the left and right sub-arrays
      await quickSort(l, splitIndex - 1, delay)
      await quickSort(splitIndex + 1, r, delay)
    }
  }

  const getMaxLenDigit = () => {
    let max = array[0][0]
    for (let i = 1; i < array.length; i++) {
      if (array[i][0] > max) {
        max = array[i][0]
      }
    }
    return max.toString().length
  }

  async function radixSort(delay) {
    const length = getMaxLenDigit()

    for (let i = 0; i < length; i++) {
      // Initializing the bucket, length 10 becuase we have 0-9 digits
      let bucket = []
      for (let t = 0; t < 10; t++) {
        bucket.push([])
      }

      // Comparing each number digit by digit starting from the last digit
      // Push the number into their corresponding bucket based on the digit that we are looking at
      for (let j = 0; j < array.length; j++) {
        if (delay !== 0) {
          await singleColoring(j, delay, 1)
        }
        let numStr = String(array[j][0])
        let targetDigit = numStr[numStr.length - 1 - i]
        if (targetDigit === undefined) {
          bucket[0].push(parseInt(array[j][0]))
        } else {
          bucket[parseInt(targetDigit)].push(parseInt(array[j][0]))
        }
      }

      // Reform the array and repeat the above process based on the length of the longest digit
      let flattened = [].concat.apply([], bucket)
      for (let k = 0; k < array.length; k++) {
        array[k][0] = flattened[k]
        if (delay !== 0) {
          await singleColoring(k, delay, 0)
        }
      }
    }
  }

  async function testAlgo() {
    // Determining the number of trials
    let trials = 100
    for (let i = 0; i < trials; i++) {
      // Making a test array
      let sampleArray = []
      let sampleLength = getRandomIntFromRange(1, 100)
      for (let j = 0; j < sampleLength; j++) {
        sampleArray.push([getRandomIntFromRange(1, 1000), 0])
      }

      // Setting the state's array to be the sampleArray, so the sorting algo will sort on the same array
      array = sampleArray.slice()
      updateArray([...array])

      if (selectedAlgo === "bubble") {
        await bubbleSort(0)
      } else if (selectedAlgo === "merge") {
        await mergeSort(0, array.length - 1, 0)
      } else if (selectedAlgo === "quick") {
        await quickSort(0, array.length - 1)
      } else if (selectedAlgo === "radix") {
        await radixSort(0)
      }

      // Call built in JS sort(), sorts the array IN-PLACE
      sampleArray.sort((a, b) => a[0] - b[0])

      // Checking both arrays' values
      console.log(isEqual(sampleArray))
    }
  }

  // Checking if the given 2 arrays are identical
  const isEqual = (sample) => {
    if (sample.length !== array.length) {
      console.log("length not matching")
      return false
    }

    for (let i = 0; i < sample.length; i++) {
      if (sample[i][0] !== array[i][0]) {
        console.log("value not matching")
        console.log(array)
        return false
      }
    }

    return true
  }

  const classes = useStyles()

  return (
    <div>
      <Grid container justify="center" className={classes.root}>
        <Typography gutterBottom className={classes.text}>
          Array Size
        </Typography>
        <ArraySlider returnVal={getVal} sortClicked={sortStarted} />
        <AlgoSpeedPicker
          returnSelectedAlgo={updateSelectedAlgo}
          returnSelectedSpeed={updateSelectedSpeed}
          sortClicked={sortStarted}
          setAlgoSpeed={setPicker}
        />
        <RandButton
          justify="flex-end"
          startRand={random}
          sortClicked={sortStarted}
        />
        {/* Disable all functionality when sort-button is clicked */}
        <SortButton
          justify="flex-end"
          startSort={sort}
          sortClicked={sortStarted}
        />
      </Grid>
      <Box className={classes.bars}>
        {array.map((barInfo, index) => {
          return (
            <ValBar
              key={index}
              barHeight={barInfo[0]}
              arraySize={array.length}
              status={barInfo[1]}
            />
          )
        })}
      </Box>
    </div>
  )
}

export default SortVisualizer
