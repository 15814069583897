import "./App.css"
import React from "react"
import { HashRouter as Router, Route } from "react-router-dom"
import NavBar from "./components/NavBar"
import Projects from "./components/Projects"
import SortVisualizer from "./components/SortVisualizer"
import TicTacToe from "./components/TicTacToe"
import FireFly from "./components/FireFly"
import Home from "./components/Home"
// import Blog from "./components/Blog"

const App = () => {
  const cards = [
    {
      name: "Sorting Visualizer",
      description:
        "This is a simple tool that helps you to visualize various sorting algorithms with animation.",
      image: "/static/SortingVisualizer.png",
      link: "/#/apps/sort",
    },
    {
      name: "TicTacToe AI",
      description: "Can you beat the TicTacToe AI? Take a stab at it : )",
      image: "/static/TicTacToe.png",
      link: "/#/apps/tictactoe",
    },
    {
      name: "FireFly Simulation",
      description:
        "Stressed and Lonely?? Dance with my fireflies will make you happy! Free of charge!",
      image: "/static/FireFly.png",
      link: "/#/apps/firefly",
    },
  ]

  return (
    <Router>
      <NavBar />
      <Route exact path="/" component={Home}></Route>
      {/* Render the projects grid if url path = /apps */}
      <Route
        exact
        path="/apps"
        component={() => <Projects cards={cards} />}
      ></Route>
      <Route path="/apps/sort" component={SortVisualizer}></Route>
      <Route path="/apps/tictactoe" component={TicTacToe}></Route>
      <Route path="/apps/firefly" component={FireFly}></Route>
    </Router>
  )
}

export default App
